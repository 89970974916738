.setting-main {
  position: fixed;
  top: 50%;
  right: 0;
  opacity: 1;
  z-index: 100;
}
.setting-main-button {
  outline: none;
  border: none;
  width: 3rem;
  height: 3rem;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  padding-left: 0.7rem;
  cursor: pointer;
  border-radius: 2rem 0 0 2rem;
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.setting-main-button:hover {
  width: 4rem;
}
.setting-main-button svg {
  animation: 1.5s rotate infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.settings-btn-close {
  outline: none;
  border: none;
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.5rem;
  border-radius: 2rem;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}
.settings-btn-close i {
  font-size: 0.8rem;
}
/* settings-main-sidebar */
.setting-sidebar-overlay {
  position: fixed;
  /* top: 0; */
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 100;
  backdrop-filter: blur(0.7rem);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.settings-sidebar-main {
  padding: 2rem;
  width: 100%;
  max-width: 450px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  box-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.3);
}

.settings-sidebar-main .light-text {
  color: #000;
}

.settings-sidebar-main .settings-color-button {
  outline: none;
  border: none;
  width: 5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.settings-sidebar-main .settings-color-button:active {
  transform: scale(0.9);
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    -webkit-transform-origin: center;
    transform-origin: center;
  }
}
