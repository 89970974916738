.app-develop-collapse {
  padding: 2rem 7rem;
  box-shadow: 0 0 0.5rem rgba(118, 85, 85, 0.1);
  margin-top: 7vh;
  display: grid;
  align-items: center;
  gap: 1vh;
  border-radius: 0.3rem;
}
.app-accordion-trigger {
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 0.9rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-develop-collapse .app-develop-collapse-title {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 500;
}

.app-accordion-content-item{
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 0.7rem 1rem;
  height: 11rem;
  border: 1px solid rgba(118, 85, 85, 0.1)
}

.app-accordion-content-item img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
}
.app-accordion-content-item h6 {
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .app-develop-collapse {
    padding: 3rem 3rem;
  }
  .app-develop-collapse .app-develop-collapse-title {
    font-size: 1.8rem;
  }
  .app-develop-collapse .app-develop-collapse-text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .app-develop-collapse {
    padding: 3rem 2rem;
  }
  .app-develop-collapse .app-develop-collapse-title {
    font-size: 1.3rem;
  }
  .app-develop-collapse .app-develop-collapse-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .app-develop-collapse {
    padding: 3rem 2rem;
    gap: 0.7rem !important;
  }
  .app-develop-collapse .app-develop-collapse-title {
    font-size: 1.25rem;
  }
  .app-develop-collapse .app-develop-collapse-text {
    font-size: 1rem;
  }
}
