/* overlay-popup */
.overlay-popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
}
/* overlay-popup */
.textarea-white:focus,
.input-theme-white:focus {
  box-shadow: none !important;
}

.input-theme-white,
.input-theme-black {
  height: 5vh;
}

.textarea-white,
.input-theme-white {
  background-color: rgb(255, 255, 255) !important;
}
.textarea-black,
.input-theme-black {
  background-color: rgb(0, 0, 0) !important;
}

.usaColor {
  background: linear-gradient(to top, #d42534 45%, #e4e5ea 30%, #002087 25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.ukColor {
  background: linear-gradient(
    to bottom,
    #012169,
    #012169 33.33%,
    #c8102e 66.66%,
    #c8102e
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.pakColor {
  background: -webkit-linear-gradient(30deg, #16a34a 10%, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.whatsAppcss {
  font-size: 1.25rem;
}

.whatsappcss {
  font-size: 5.25rem;
}

.fa-whatsApp {
  animation: beat 0.25s infinite alternate;
  transform-origin: center;
  color: #25d06f;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

@media screen and (max-width: 767px) {
  .center-on-small {
    text-align: center !important;
  }
}

.contact-main {
  border-right: 1px solid #C7C8C9
}

@media screen and (max-width: 767px) {
  .contact-main {
      border-right: none
  }

  .accordion {
      margin-top: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-title {
      font-size: 45px !important
  }
  .contact-btn-title{
      font-size: 23px !important;
  }
}