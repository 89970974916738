/* unial-post */
.unial-post {
  cursor: pointer;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.unial-post:hover {
  background-color: #f2f2f2;
}
.unial-post .unial-post-body {
  display: grid;
  align-items: center;
  justify-items: flex-start;
  padding: 1rem;
}
.unial-post .unial-post-body h1 {
  font-size: 1.1rem;
  line-height: 1;
}
.unial-post .unial-post-img img {
  width: 100%;
  height: 25vh;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

@media screen and (max-width: 1200px) {
  .unial-post .unial-post-body h1 {
    font-size: 1.3rem;
    line-height: 1;
  }
  .unial-post .unial-post-img img {
    height: 29vh;
  }
}
