* {
  margin: 0;
  padding: 0;
}

.theme-outline {
  border-radius: 0.35rem !important;
  border: 1px dashed rgb(0,0,0,0.1) !important;
  box-shadow: 0rem 0rem 0.2rem rgba(0, 0, 0, 0.1) !important;
}

.bg-dark-mode {
  background: #000000 !important;
}
.bg-white-mode {
  background: #ffffff !important;
}
.text-mode-white {
  color: #ffffff !important;
}
.text-mode-dark {
  color: #000000 !important;
}

.shadow-dark-mode {
  box-shadow: 0 0 0.1rem rgba(255,255,255, 1) !important;
}
.shadow-dark-mode-opacity {
  box-shadow: 0 0 0.1rem rgba(255,255,255, 0.6) !important;
}
.ifBlockBgExists {
  background: #000 !important;
}
.ifPurpleBgExists {
  background: linear-gradient(to right bottom, #6509e2 40%, #5a02b4 70%) !important;
  background-color: #5a02b4 !important;
}
.metaverse-bg {
  background: linear-gradient(90deg, rgba(27,18,29,1) 0%, rgba(18,15,47,1) 50%, rgba(27,18,29,1) 100%);
}

#ripple {
  height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#ripple canvas{
  height: 75vh;
  width: 100%;
}
.theme-row {
  row-gap: 2rem !important;
}
@media screen and (max-width: 991px) {
  .theme-row {
    row-gap: 1.3rem !important;
  }
}