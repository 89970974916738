/* Blockchain Service Page */
.main-service-padding {
  padding: 3rem 0;
}
/* web-services */
.web-services {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.web-services-text h1 {
  font-size: 3.5rem;
  line-height: 1;
}
.web-services .web-services-img img {
  width: 100%;
  height: 55vh;
  object-fit: contain;
}

/* blockchain */
.blockchain-banner-bg {
  background-image: url("../../../assets/banner_bg.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.blockchain-banner-bg::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.23deg,
    #030b15 5.68%,
    rgba(3, 11, 21, 0.42) 81.9%
  );
  z-index: -1;
}
.blockcahin-banner-shape-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.blockcahin-banner-shape-wrap img.img-one {
  top: 28%;
  left: -3%;
  animation: leftToRight 5s infinite linear;
}
.blockcahin-banner-shape-wrap img.img-two {
  left: auto;
  right: 8%;
  top: 20%;
}
.blockcahin-banner-shape-wrap img.img-three {
  left: auto;
  right: -2%;
  top: auto;
  bottom: 14%;
  animation: alltuchtopdown 3s infinite linear;
}
.banner-content {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.banner-content .title {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
}

/* who-we-content */
.who-we-img .shape-img {
  position: absolute;
  top: 0;
  left: 0;
}
.who-we-img .blockchainimg {
  width: 100%;
  height: 51vh;
  object-fit: contain;
}
.who-we-row {
  align-items: center;
  justify-content: center;
}
.who-we-content {
  max-width: 470px;
  margin-right: auto;
}
.who-we-content h1 {
  font-size: 2.85rem;
  font-weight: 700;
}
.who-we-content p {
  font-size: 1.1rem;
}
.we-button {
  color: #00c4f4;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 1rem 3rem;
  text-align: center;
  border-radius: 2rem;
  background: #040e18;
  text-transform: uppercase;
  touch-action: manipulation;
  border: 2px solid #00c4f4;
  background-clip: padding-box;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) ease-in-out 0s;
}
.we-button:hover {
  background: linear-gradient(
    90deg,
    rgba(0, 196, 244, 1) 0%,
    rgba(4, 12, 21, 1) 49%,
    rgba(0, 196, 244, 1) 100%
  );
}
/* partner-wrap */
.partner-wrap .partner-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.partner-wrap .partner-list li {
  width: 20%;
  height: 17vh;
  cursor: pointer;
  margin-top: -1px;
  margin-left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}
.partner-wrap .partner-list li img {
  opacity: 1;
  transition: 0.3s linear;
}
.partner-wrap .partner-list li:hover img {
  opacity: 0.8;
}

/* technologies-work */
.technologies-work {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  border-radius: 0.5rem;
  cursor: pointer;
}

/* portfolio-img-content */
.portfolio-text-content,
.portfolio-img-content {
  padding: 1rem 0;
}
.portfolio-img-content img {
  width: 100%;
  height: 37vh;
  object-fit: contain;
}
/* cooperate-card-border-white */
.cooperate-card-border {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0rem 0rem 0.2rem 0.2rem;
}
.cooperate-card-border-white {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0rem 0rem 0.2rem 0.2rem;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
@keyframes leftToRight {
  0% {
    transform: rotateX(0deg) translateX(0px);
  }
  50% {
    transform: rotateX(0deg) translateX(50px);
  }
  100% {
    transform: rotateX(0deg) translateX(0px);
  }
}

@media screen and (max-width: 1200px) {
  .banner-content .title {
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
  }
  .blockchain-banner-bg {
    min-height: 55vh;
  }
  .blockcahin-banner-shape-wrap img.img-one,
  .blockcahin-banner-shape-wrap img.img-two,
  .blockcahin-banner-shape-wrap img.img-three {
    width: 100px;
    height: 100px;
  }
  .web-services-text h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 991px) {
  .banner-content .title {
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
  }
  .who-we-content {
    max-width: none;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .who-we-row {
    row-gap: 3rem;
  }
  .partner-wrap .partner-list li {
    width: 33%;
  }
  /* web-services-row */
  .web-services-row {
    row-gap: 4rem;
  }
  .web-services {
    height: auto;
    padding: 5rem 0;
  }
  .web-services-text {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  .web-services-text h1 {
    font-size: 2.5rem;
  }
  .web-services .web-services-img img {
    width: 100%;
    height: 45vh;
    object-fit: contain;
  }
}

@media screen and (max-width: 767px) {
  .banner-content .title {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
  }
  .who-we-img .blockchainimg {
    height: 37vh;
  }
  .who-we-content h1 {
    font-size: 2rem;
    font-weight: 700;
  }
  .who-we-content p {
    font-size: 1rem;
  }
  .partner-wrap .partner-list li {
    width: 50%;
    height: 123px;
  }
  .web-services-text h1 {
    font-size: 2rem;
  }
  .web-services-text p {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 550px) {
  .who-we-img .blockchainimg {
    height: 35vh;
  }
  .who-we-content h1 {
    font-size: 1.7rem;
  }
  .who-we-content p {
    font-size: 0.9rem;
  }
  .who-we-content button {
    font-size: 0.9rem;
  }
  .who-we-img .shape-img {
    width: 85px;
    height: 85px;
  }
  .partner-wrap .partner-list li {
    width: 100%;
    height: 123px;
  }
}
