.col-xl-12 {
  text-align: center;
  margin-top: 100px;
}

.col-xl-12 h1 {
  text-align: center;
  font-size: 30px;

  font-weight: bolder;
  padding-top: 20px;
  padding-bottom: 40px;
}

.text-gradient-white {
    /* background: linear-gradient(to right, #180202, #7f0000) !important; */
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}
.text-gradient-dark {
    /* background: linear-gradient(to right, #b70101, #9e0101) !important; */
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.col-xl-12 h6 {
  font-weight: bolder;
}

.homebannerbtncon a {
  background: #7f0000;
  border: 2px solid #7f0000;
  color: #fff;
  border-radius: 4px;
  padding: 20px;
  font-family: Inter SemiBold;
  font-size: 20px;
  text-decoration: none;
  font-weight: bold;
}

.homebannercontent {
  margin-bottom: 5%;
}

.mainTextHeader {
  font-size: 2.5rem !important;
  padding-bottom: 20px !important;
}

.subTextHeader {
  font-size: 1.3rem;
  font-weight: 500;
  color: white;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  transition-duration: 0.2s;
}

.subTextHeader:hover {
  transition-duration: 0.2s;
}

.subTextHeader:hover {
  background-color: #fff !important;
  color: #7f0000 !important;
}

.subTextHeaderIcon {
  position: relative;
  margin-left: 5px;
  animation: toFro 1s infinite alternate-reverse;
}

@keyframes toFro {
  0% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(-3px);
  }
}

/* @media (min-width: 992px) {

    .modalBox {
        width: 1000px;
    }
}

@media (max-width: 992px) {

    .modalBox {
        width: 100%;
    }
}



@media (max-width: 768px) {

    .modalBox {
        width: 100%;
    }
} */
