.blog-details-hero {
  display: grid;
  align-items: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.blog-details-hero-title {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}
.blog-details-hero-title h1 {
  font-size: 2.5rem;
  font-weight: 900;
  color: #7f0000;
  text-align: center;
}
.blog-details-hero-img img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  margin-top: 1vh;
}
.blog-details-hero-title .metatags .metatags-button {
  border: none;
  outline: none;
  color: #7f0000;
  border-radius: 0.345rem;
  background-color: #fff;
  border: 1px solid #7f0000;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.blog-details-hero-title .metatags .metatags-button:hover {
  background-color: #7f0000;
  color: #fff;
}
.blog-details-hero-title .socialicon-buttons {
  color: #7f0000;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background-color: #fff;
  border: 1px solid #7f0000;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

.blog-main {
  width: 93%;
  margin: 0 auto;
  background: #ffffff;
}

.profile-box {
  background: #f2f2f2;
  top: 9vh;
}
.profile .cutom-profile-img {
  position: absolute;
  top: -5vh;
}
.profile .custome-margin {
  margin-top: 4rem;
}
/* */
.gradient-chip {
  z-index: 1;
}
.gradient-chip .button-chip {
  outline: none;
  border: none;
  width: 11rem;
  font-size: 1.2rem;
  background-color: #ffffff;
  box-shadow: 6px 6px #8239af;
}

.gradient-chip .chip-text {
  height: 8vh;
  width: 65%;
  background-color: #a100ffed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11rem;
  opacity: 1;
  z-index: 1;
}
.chip-boxes {
  height: 8vh;
  width: 40%;
  opacity: 1;
  z-index: 0;
  top: 0;
  right: 0;
  position: absolute;
  background: #a100ffed;
  background: linear-gradient(to right, #4b007a, #a100ffed);
}

@media screen and (max-width: 1300px) {
  .blog-details-hero {
    width: 85%;
    margin: 0 auto;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1200px) {
  .blog-details-hero-title h1 {
    font-size: 2rem;
    font-weight: 900;
    color: #7f0000;
  }
}

@media screen and (max-width: 991px) {
  .blog-details-hero {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    width: 93%;
    gap: 1rem;
    padding: 1rem 0 2rem 0 !important;
  }
  .blog-details-hero-title,
  .blog-details-hero-img {
    width: 100%;
  }
  .profile-box {
    top: 0;
    margin: 5vh 0;
    padding: 3rem 0;
  }
  .profile-box .profile {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2rem;
  }
  .profile .cutom-profile-img {
    position: relative;
    top: 0;
  }
  .profile .custome-margin {
    margin: 0;
  }
  /*  */
  .gradient-chip .chip-text {
    height: 7vh;
    gap: 1rem;
    width: 100%;
  }
  .gradient-chip .button-chip {
    font-size: 1rem;
    width: 8rem;
    height: 4vh;
  }
  .chip-boxes {
    display: none;
  }

  .gradient-chip .chip-text h1 {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 600px) {
  .blog-details-hero {
    width: 95%;
  }
  .blog-details-hero-img img {
    width: 100%;
    height: 29vh;
  }
  .blog-details-hero-title h1 {
    font-size: 1.7rem;
  }
}
