.portfolio-section {
  margin: 7vh 0;
}
/* Animated Button */
.animated-button .button-circle::before {
  content: "";
  left: -1.7rem;
  top: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  border-radius: 50px;
  transform: translateY(-50%);
  border: 1px solid #7f0000;
  transition: all 0.5s ease-in-out;
}
.animated-button:hover .button-circle::before {
  width: 15rem;
  height: 2.7rem;
}
.animated-button .animated-button-text {
  color: #7f0000;
  font-size: 1.3rem;
}

/* accelerated-box */
.accelerated-box {
  padding: 4rem 7rem;
  box-shadow: 0 0 0.5rem rgb(0 0 0 / 10%);
  background: white;
}
.accelerated-box .accelerated-box-title {
  font-size: 2.7rem;
  line-height: 1;
  font-weight: 500;
}
.accelerated-box .accelerated-box-text {
  font-size: 1.3rem;
  max-width: 850px;
  line-height: 1.2;
}

/* Portfolios */
.portfolio-titles .line {
  opacity: 1;
  z-index: -1;
  width: 3rem;
  border: 1px solid #ff9500;
}
.portfolio-slides {
  opacity: 1;
  z-index: -1;
}
.portfolio-slides .portfolio-slider-img .slider-img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  pointer-events: none;
}

.custom-radius {
  border-radius: 5% 5% 0 0;
}

/* portfolio-slider-content */
.portfolio-slides .portfolio-slider-content {
  opacity: 1;
  z-index: 50;
  background: #fff;
  padding: 1.5rem 1.2rem 2rem 1.2rem;
  box-shadow: 0 0 10px rgb(0 0 0 / 14%);
}
.portfolio-slides .portfolio-slider-content .content-box-title {
  color: #7f0000;
  font-size: 1.4rem;
}

/* .swiper-button, */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 75% !important;
  bottom: 0 !important;
  border: 2px solid #7f0000;
  border-radius: 50% !important;
  width: 3.5rem !important;
  height: 3.5rem !important;
  transition: all 0.3s ease-in-out 0s;
  opacity: 1;
  z-index: 1;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  transform: scale(1.1);
}
.swiper-button-prev {
  left: 25% !important;
  right: auto;
}
.swiper-button-next {
  right: 25% !important;
  left: auto;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #7f0000 !important;
  content: "\2190" !important;
  margin-bottom: 1rem !important;
  font-size: 45px !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #7f0000 !important;
  content: "\2192" !important;
  margin-bottom: 1rem !important;
  font-size: 45px !important;
}

/* actioncall-icon-content-left */
.actioncall-icon-content-left {
  position: absolute;
  top: 4vh;
  left: 2vw;
}
.actioncall-icon-content-right {
  position: absolute;
  top: 3vh;
  right: 2vw;
}
.actioncall-icon-content-bottom-left {
  position: absolute;
  bottom: 5vh;
  left: 3vw;
}
.actioncall-icon-content-bottom-right {
  position: absolute;
  right: 3vw;
  bottom: 5vh;
}
.actioncall-icon-content-bottom-right .icon-content-bottom-right,
.actioncall-icon-content-bottom-left .icon-content-bottom-left,
.actioncall-icon-content-right .icon-content-right,
.actioncall-icon-content-left .icon-content-left {
  display: flex;
  align-items: center;
  text-align: start;
  gap: 0.7rem;
}
.actioncall-icon-content-bottom-left .icon-content-bottom-left i,
.actioncall-icon-content-left .icon-content-left i {
  font-size: 2rem;
}

.actioncall-icon-content-bottom-left .icon-content-bottom-left span,
.actioncall-icon-content-left .icon-content-left span,
.actioncall-icon-content-right .icon-content-right span,
.actioncall-icon-content-bottom-right .icon-content-bottom-right span {
  font-size: 0.9rem;
}
.actioncall-icon-content-bottom-left .icon-content-bottom-left i,
.actioncall-icon-content-left .icon-content-left i,
.actioncall-icon-content-right .icon-content-right img,
.actioncall-icon-content-bottom-right .icon-content-bottom-right img {
  animation: animate-beat 350ms infinite alternate-reverse ease-in-out;
}



/* Heart animate-skype animation */
@keyframes animate-beat {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@media screen and (max-width: 1300px) {
  .swiper-button-prev {
    left: 25% !important;
  }
  .swiper-button-next {
    right: 25% !important;
  }
  .portfolio-slides .portfolio-slider-content {
    padding: 1.5rem 1rem 2rem 1rem;
  }
  .portfolio-slides .portfolio-slider-content .content-box-title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 991px) {
  .swiper-button-prev {
    left: 5% !important;
  }
  .swiper-button-next {
    right: 5% !important;
  }
  .accelerated-box {
    padding: 3rem 3rem;
  }
  .accelerated-box .accelerated-box-title {
    font-size: 1.5rem;
  }
  .accelerated-box .accelerated-box-text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .swiper-button-prev {
    left: 0% !important;
  }
  .swiper-button-next {
    right: 0% !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 3rem !important;
    height: 3rem !important;
  }
  .portfolio-sections {
    margin: 5vh 0;
  }
  .accelerated-box {
    padding: 3rem 2rem;
  }
  .accelerated-box .accelerated-box-title {
    font-size: 1.3rem;
  }
  .accelerated-box .accelerated-box-text {
    font-size: 1rem;
  }
  .actioncall-icon-content-bottom-left .icon-content-bottom-left span,
  .actioncall-icon-content-left .icon-content-left span,
  .actioncall-icon-content-right .icon-content-right span,
  .actioncall-icon-content-bottom-right .icon-content-bottom-right span {
    display: none;
  }

  .actioncall-icon-content-left {
    top: 3vh;
    left: 4vw;
  }
  .actioncall-icon-content-right {
    top: 3vh;
    right: 3vw;
  }
  .actioncall-icon-content-bottom-left {
    bottom: 3vh;
    left: 5vw;
  }
  .actioncall-icon-content-bottom-right {
    right: 5vw;
    bottom: 3vh;
  }
  .actioncall-icon-bottom-left a i,
  .actioncall-icon-left a i {
    font-size: 1.5rem;
  }
  .actioncall-icon-bottom-right a img,
  .actioncall-icon-right a img {
    width: 21px !important;
    height: 21px !important;
  }
}
@media screen and (max-width: 550px) {
  .swiper-button-prev {
    left: 5% !important;
  }
  .swiper-button-next {
    right: 5% !important;
  }
  .accelerated-box {
    padding: 3rem 2rem;
    gap: 0.7rem !important;
  }
  .accelerated-box .accelerated-box-title {
    font-size: 1.25rem;
  }
  .accelerated-box .accelerated-box-text {
    font-size: 1rem;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 45% !important;
  }
  .portfolio-sections {
    margin: 0vh 0 5vh 0;
  }
}
