/* ifBgExistColor */
.ifLinkIsBlack ul li a{ 
    color: #fff !important;
}
/* ifBgExistColor */
.top-header {
    position: sticky;
    top: 0;
    z-index: 11;
}

.text__right ul li a {
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    text-decoration: none;
}

/* Theme */
.text__right ul li .nav-link-white {
    color: #fff !important;
}
.text__right ul li .nav-link-dark {
    color: #000 !important;
}

.whatsapp-icon {
    position: fixed;
    bottom: 10px;
    right: 10px;
}
/* .navbar {
    background-color: #fff;
} */

.text__right ul li a:hover {
    color: brown;
    font-weight: bold;
    border-bottom: 3px solid #7f0000;
    cursor: pointer;
}

.backEffect {
    text-transform: uppercase;
    background-color: #7f0000;
    border-radius: 5px;
    font-weight: 600;
    padding: 8px 17px 8px 17px;
    font-size: 14px;
}

.text__right ul li {
    padding-right: 15px;
}

.backEffect:hover {
    background-color: white;
    color: #7f0000;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.bg-white {
    background-color: #fff !important;
}

.mega-box {
    position: absolute;
    top: 65px;
    background-color: #fff;
    width: 100%;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    box-shadow: 0px 8px 8px -7px grey;
}

.mega-box .content {
    background-color: white;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
}

.mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.mega-box .content .row:nth-child(1),
.mega-box .content .row:nth-child(2) {
    border-top: 0px;
}

.content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
}

@media (max-width: 1120px) {
    .text__right ul li {
        padding-right: 0px;
        list-style: none;
    }

    .backEffect {
        text-transform: uppercase;
        background-color: #7f0000;
        border-radius: 20px;
        font-weight: 600;
        padding: 12px;
        font-size: 14px;
    }

    .d-flex button {
        margin-left: 10px;
    }
}
.ArrowIconNav {
    display: none !important;
}

@media (max-width: 991px) {
    .text__right ul li a:hover {
        border: none;
    }

    .text__right {
        background-color: white;
        color: white;
    }

    .text__right ul li a {
        color: #fff;
        padding: 15px;
        text-transform: capitalize;
    }

    .backEffect {
        text-transform: capitalize;
        background-color: #7f0000;
        border-radius: none;
        border: none;
    }

    .backEffect:hover {
        background-color: #25d06f;
        color: rgb(0, 0, 0);
        border-radius: none;
    }
    .ArrowIconNav {
        display: block !important;
        padding-top: 4px !important;
        padding-left: 4px !important;
    }
}

.dropdown-menu {
    width: 100%;
    padding-right: 6%;
    padding-left: 6%;
    /* animation: mymove 0.3s; */
    animation-fill-mode: alternate-reverse;
}

.shadow {
    overflow-y: scroll;
    height: 500px;
}

@media only screen and (max-width: 991px) {
    .shadow {
        height: auto;
        width: 350px;
    }
}
/*
@media (max-width:1199px) {
    .dropdown-menu {
        position: absolute;
        top: 70px !important;
    }
} */

/* @keyframes mymove {
    from {
        top: 60px;
    }
    to {
        top: 90px;
    }
} */
