.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hero-content-body {
  display: grid;
  align-items: center;
  justify-items: flex-end;
  height: 45vh;
  width: 50%;
  flex: 0 0 auto;
}
.hero-content-text {
  max-width: 650px;
  width: 100%;
  gap: 1rem;
}
.hero-content-text p {
  font-size: 1.25rem;
}
.hero-content-text h1 {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
}
.hero-content-img {
  width: 50%;
  flex: 0 0 auto;
}
.hero-content-img img {
  width: 100%;
  height: 45vh;
  object-fit: cover;
  object-position: top;
}
.hero-team-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.7rem;
  border: 0;
  outline: 0;
  background: transparent;
}
.hero-team-button::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  height: 0.1rem;
  background: #0055ff;
  transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}

.hero-team-button:hover::after {
  width: 100%;
}
.carreer-card-title h1 {
  font-size: 3rem;
  line-height: 1;
}
/* carreer-card-row */
.carreer-card-row {
  row-gap: 20vh;
  margin-top: 19vh;
}
.card-border {
  border-top: 7px solid #7f0000;
  height: auto;
  padding-top: 6rem;
  /* padding-bottom: 1rem; */
}
.career-card-img {
  position: absolute;
  top: -15vh;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.career-card-img img {
  width: 85%;
  height: 25vh;
  object-fit: cover;
}

/*  */
.career-body-head {
  background-color: #f2f2f2;
  border-radius: 10px;
  width: 800px;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.career-body-head-title {
  font-size: 18px;
  font-weight: 700;
  padding: 6px;
  margin: 0;
  color: gray;
}
.career-body-head-title :hover {
  cursor: pointer;
}
/* job-card */
.job-card {
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0rem 0rem 0.3rem rgba(0, 0, 0, 0.3);
  transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.job-card:hover {
  box-shadow: 0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.3);
}

.job-card-button {
  padding: 6px;
  border: none;
  outline: none;
  color: #7f0000;
  border-radius: 0.345rem;
  background-color: #fff;
  border: 1px solid #7f0000;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.job-card-button:hover {
  background-color: #7f0000;
  color: #fff;
}
.career-middle-section-img {
  width: 500px;
  /* height:auto */
}

@keyframes size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1300px) {
  .hero-content-body {
    padding-left: 1rem;
  }
  .hero-content-text {
    max-width: 600px;
    width: 100%;
    gap: 1rem;
  }
  .hero-content-text p {
    font-size: 1rem;
  }
  .hero-content-text h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 991px) {
  .carrer-container {
    width: 95%;
  }
  .career-body-img {
    width: 100%;
  }
  .job-card-button {
    font-size: 0.8rem;
  }
  .job-card .job-card-title {
    font-size: 1.1rem;
  }

  .hero-content {
    flex-direction: column;
    justify-content: center;
  }
  .hero-content-body {
    height: auto;
    width: 95%;
    margin: 0 auto;
    justify-items: center;
    text-align: center;
    padding: 2rem 0;
  }
  .hero-content-text {
    max-width: none;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .hero-content-text p {
    font-size: 1rem;
  }
  .hero-content-text h1 {
    font-size: 3rem;
  }
  .hero-content-img {
    width: 100%;
  }
  .hero-content-img img {
    height: 100%;
  }
  .carreer-card-title h1 {
    font-size: 2rem;
    line-height: 1;
  }
  .carreer-card-row {
    row-gap: 19vh;
    margin-top: 17vh;
  }
  .career-card-img img {
    width: 85%;
    height: 25vh;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .hero-content-text {
    gap: 0;
  }
  .hero-content-text p {
    font-size: 1rem;
  }
  .hero-content-text h1 {
    font-size: 2.5rem;
  }
  .carreer-card-row {
    row-gap: 35vh;
    margin-top: 32vh;
  }
  .career-card-img {
    top: -29vh;
  }
  .card-border {
    padding-top: 3rem;
  }
  .career-card-img img {
    width: 95%;
    height: 35vh;
    object-fit: cover;
  }
  .job-card p{
    font-size: 0.9rem;
  }
}
