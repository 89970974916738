.stories-hero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stories-hero-content,
.stories-hero-posts {
  height: 35vh;
  width: 49.5%;
  flex: 0 0 auto;
}
.stories-hero-content {
  display: flex;
  align-items: flex-start;
  justify-content: end;
  flex-direction: column;
  background-color: #f2f2f2;
  padding: 0 2rem;
}
.stories-hero-content p {
  color: #000;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 0.5;
}
.stories-hero-content h1 {
  color: #000;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 0.9;
}

/* unial-post */
.unial-post {
  cursor: pointer;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.unial-post:hover {
  background-color: #f2f2f2;
}
.unial-post .unial-post-body {
  display: grid;
  align-items: center;
  justify-items: flex-start;
  padding: 1rem;
}
.unial-post .unial-post-body h1 {
  font-size: 1.7rem;
  line-height: 1;
}
.unial-post .unial-post-img img {
  width: 100%;
  height: 25vh;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
}

@media screen and (max-width: 1400px) {
  .stories-hero-content h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1200px) {
  .stories-hero {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  .stories-hero-content,
  .stories-hero-posts {
    height: auto;
    width: 100%;
    flex: 0 0 auto;
    margin: 0 auto;
  }
  .stories-hero-content h1 {
    margin-bottom: 0rem !important;
  }
  .stories-hero-content {
    padding: 3rem 1rem;
  }
  .unial-post .unial-post-body h1 {
    font-size: 1.5rem;
    line-height: 1;
  }
  .unial-post .unial-post-img img {
    height: 29vh;
  }
}
@media screen and (max-width: 767px) {
  .stories-hero-content h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  .stories-hero-content h1 {
    font-size: 2rem;
  }
  .stories-hero-content p {
    font-size: 0.9rem;
  }
  .stories-hero-content {
    padding: 2rem 1rem;
  }
}
