.footerupperSection {
    height: auto;
}

.footerupperSectionMainBtn {
    padding: 10px;
    margin: 10px 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #7F0000;
    color: white;
    font-weight: bold;
    border: 1px solid #7F0000;
    transition-duration: 0.2s;
}

.footerupperSectionMainBtn:hover {
    background-color: #fff !important;
    color: #7F0000 !important;
    transition-duration: 0.2s;
}

.footerlowerSection {
    height: 50px;
    color: black;
}

.footerSocialIcon {
    color: #7F0000;
    cursor: pointer;
}

.footerLowerSectionOuterBox {
    background-color: rgb(231, 230, 230);
    color: #7F0000;
}

.footerLink {
    text-decoration: none;
    color: #7F0000 !important;
    font-weight: 500;
    margin: 2px 0px;
}
.footer-link-dark-mode {
    text-decoration: none;
    color: #a5a4a4 !important;
    font-weight: 400;
    margin: 2px 0px;
}

.footerLink:hover {
    text-decoration: underline;
}

    


@media (max-width: 1200px) {
    .footerLowerSectionOuterBox {
        padding-bottom: 10%;
    }
}

@media (max-width: 992px) {

    .footerLowerSectionOuterBox {
        padding-bottom: 12%;
    }
}

@media (max-width: 768px) {

    .footerLowerSectionOuterBox {
        padding-bottom: 15%;
    }

    .footerLink {
        color: black !important;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 576px) {

    .footerLowerSectionOuterBox {
        padding-bottom: 25%;
    }

    .footerSocialIcon {
        margin: 0px 8px;
    }

    .footerlowerSection {
        height: 80px;
    }
}