.mobile-service-double {
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    background-color: rgb(231, 230, 230);
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    opacity: 1;
    z-index: 10;
}

.doubleNavItems {
    margin: 0 10px;
    cursor: pointer;
    padding: 10px;
    font-size: 1em;
    color: black !important;
    text-decoration: none;
    transition-duration: 0.2s;
}

.navItemActive {
    background-color: rgb(73, 73, 73);
    border-radius: 5px;
    color: #fff !important;
    font-weight: bold;
    transition-duration: 0.2s;
}