/* detial-page */
.portfolio-details {
    margin-top: 9vh;
}

.portfolio-details .portfolio-details-images .portfolios-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* details-title */
.portfolio-details .portfolio-details-content {
    position: sticky;
    top: 13vh;
    height: auto;
}
.portfolio-details .portfolio-details-content .line {
    opacity: 1;
    z-index: 1;
    width: 5rem;
    border: 1px solid #ff9500;
}
.portfolio-details .portfolio-details-content .details-title{
    color: #7f0000;
}







.divleft{
    width: 50%;
    /* overflow: scroll ;
    height: 100vh; */
}
.divright{
    width: 50%;
    padding-top: 80px;
    position: sticky;
    /* margin-left:44% ; */
    top: 7%;
    height: 90vh;
}
.maindiv{
    display:flex
}
::-webkit-scrollbar {
    display: none;
}
/* .div2right{
    justify-content: space-between;
    word-spacing: 10px;
}
.div3{
    align-items: right;
    justify-content: right;
} */
.div1,.div3{
    width: 25%;
}
.div2{
    width: 35%;
}
.enddiv{
    justify-content: space-between;
}
.text1{
    font-size: 38px;
}
.lastdiv{
    background-color: rgb(240, 239, 239);
}

@media only screen and (max-width: 1115px) {
.OP{
    padding-top: 0 !important;
}
.hr1{
    display: none;
}
}

@media only screen and (max-width: 818px) {
.divright{
    padding-top: 60px;
}
}
@media only screen and (max-width: 760px) {
    .maindiv{
        display: block;
    }
    .divleft,.divright{
        width: 100%;
    }
    .divright{
        padding-right: 15px !important;
    }
    .divleft{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .firstdiv{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .hr1{
        display: block;
    }
    }

@media only screen and (max-width: 595px) and (min-width: 400px) {
    .img1{
        height: 500px ;
    }
    .img2{
        height: 300px;
    }
}
@media only screen and (max-width: 400px){
    .img1{
        height: 400px ;
    }
    .img2{
        height: 200px;
    }
}

@media only screen and (max-width: 410px){
    .btn1{
        height: 70px!important;
    }
}