.products-card {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    flex-direction: column;
    gap: 1.3rem;
}
.products-img {
    display: flex;
    align-items: center;
}
.products-img img {
    width: 100%;
    height: 29vh;
    object-fit: cover;
    border-radius: 0.35rem 0.35rem 0rem 0rem;
    box-shadow: 0rem 0rem 0.1rem rgba(0, 0, 0, 0.1);
}
.products-text {
    display: grid;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
    gap: 0.5rem;
}
.products-text .products-name {
    color: #7f0000;
  font-size: 1.4rem;
}