.succes-main {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
/* .brand-video-container */
.brand-video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  z-index: 1;
}
.brand-video-container .brand-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.brand-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.brand-img p {
  text-align: center;
  font-weight: 600;
}
.brand-img .logo {
  width: 100%;
  height: 35vh;
  object-fit: cover;
}

/* title */
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title .title-child-1,
.title .title-child-2,
.title .title-child-3 {
  font-size: 5rem;
  font-weight: 900;
  position: relative;
  opacity: 1;
  z-index: 1300;
  filter: brightness(1.1);
  text-shadow: rgb(0 0 0 / 5%) 0px 0px 1px;
  transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  line-height: 1;
}
.title .title-child-1::before,
.title .title-child-2::before,
.title .title-child-3::before {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 1500;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title .title-child-1::before {
  content: "THANK";
  background-image: linear-gradient(90deg, #007cf0, #00dfd8);
  animation: 6s ease infinite normal running 0s animate1;
}
.title .title-child-2::before {
  content: "YOU";
  background-image: linear-gradient(90deg, #7928ca, #ff0080);
  animation: 6s ease infinite normal running 0s animate2;
}

.title .title-child-3::before {
  content: "CONGRATULATIONS";
  background-image: linear-gradient(90deg, #ff4d4d, #f9cb28);
  animation: 6s ease infinite normal running 0s animate3;
}

/* button-theme */
.button-theme {
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.85rem 3rem;
  cursor: pointer;
  margin-top: 5vh;
  border: 1px solid #000;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.button-theme:hover {
  background: #fff;
  color: #000;
  transform: scale(1.1);
}

.gif-button {
  position: absolute;
  bottom: 5vh;
  right: 3vw;
  outline: none;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.gif-button .gif {
  width: 5rem;
  height: 5rem;
}
.gif-button:active {
  transform: scale(2);
}
.gif-button:hover {
  transform: scale(1.1);
}

@keyframes animate1 {
  0%,
  16.667%,
  100% {
    opacity: 1;
  }
  33.333%,
  83.333% {
    opacity: 0;
  }
}

@keyframes animate2 {
  0%,
  100% {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
  16.667%,
  66.667% {
    opacity: 0;
  }
}

@keyframes animate3 {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .gif-button .gif {
    width: 3.5rem;
    height: 3.5rem;
  }
  .button-theme {
    padding: 0.85rem 2rem;
    margin-top: 1rem;
    font-size: 1.1rem;
  }
  .title .title-child-1,
  .title .title-child-2,
  .title .title-child-3 {
    font-size: 5rem;
  }
  .gif-button {
    position: absolute;
    bottom: 13vh;
    right: 10vw;
  }
  .title {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .button-theme {
    padding: 0.85rem 2rem;
    font-size: 1rem;
  }
  .title .title-child-1,
  .title .title-child-2,
  .title .title-child-3 {
    font-size: 3.3rem;
  }
  .brand-img p {
    text-align: center;
    font-weight: 600;
    font-size: 0.95rem;
    width: 80%;
    margin: 0 auto;
  }
  .brand-img .logo {
    width: 100%;
    height: 21vh;
    object-fit: contain;
  }
  .title {
    gap: 1rem;
  }
  .column-on-md {
    flex-direction: column !important;
    gap: 0 !important;
  }
  .success-button {
    width: 100% !important;
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 550px) {
  .button-theme {
    padding: 0.65rem 1.5rem;
    font-size: 0.75rem;
  }
  .title .title-child-1,
  .title .title-child-2,
  .title .title-child-3 {
    font-size: 2.3rem;
  }
  .brand-img p {
    font-size: 0.75rem;
  }
  .brand-img .logo {
    height: 19vh;
  }
  .title {
    gap: 0.7rem;
  }
}

@media screen and (max-width: 425px) {
  .button-theme {
    padding: 0.65rem 2rem;
  }
  .title .title-child-1,
  .title .title-child-2,
  .title .title-child-3 {
    font-size: 2.5rem;
  }
  .brand-img p {
    font-size: 0.75rem;
  }
  .brand-img .logo {
    height: 19vh;
  }
}

@media screen and (max-width: 375px) {
  .button-theme {
    padding: 0.65rem 2rem;
    margin-top: 0.7rem;
  }
  .title .title-child-1,
  .title .title-child-2,
  .title .title-child-3 {
    font-size: 2rem;
  }
  .brand-img p {
    font-size: 0.75rem;
  }
  .brand-img .logo {
    height: 19vh;
  }
}
