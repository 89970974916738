.blog-hero {
  margin-top: 9vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blog-hero-content,
.blog-hero-posts {
  /* height: 35vh; */
  width: 49.5%;
  flex: 0 0 auto;
  /* padding: 1rem 0; */
}
.blog-hero-content {
  display: flex;
  align-items: flex-start;
  justify-content: end;
  flex-direction: column;
  background-color: #f2f2f2;
  padding: 0 2rem 0.5rem 2rem;
}
.blog-hero-content p {
  color: #000;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 0.5;
}
.blog-hero-content h1 {
  color: #000;
  font-size: 3rem;
  font-weight: 600;
  line-height: 0.9;
}

/* unial-post */
.unial-post {
  cursor: pointer;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.unial-post:hover {
  background-color: #f2f2f2;
}
.unial-post .unial-post-body {
  display: grid;
  align-items: center;
  justify-items: flex-start;
  padding: 1rem;
}
.unial-post .unial-post-body h1 {
  font-size: 1.2rem !important;
  line-height: 1;
}
.unial-post .unial-post-img img {
  width: 100%;
  height: 25vh;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

.mail-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-mail {
  background: url("https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-demo-29/oh__img93.jpg") center/cover no-repeat;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-button {
  outline: none;
  border: none;
  background-color: #dc2828;
  color: #fff;
  font-weight: 500;
  width: 10rem;
  border-radius: 0.5rem;
  transition: all 1s linear 0s;
  padding: 0.7rem 0;
}
.blog-button:hover {
  background: #000;
}
.blog-button:hover i {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-timing-function: linear;
}
.let-talk {
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.let-talk-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

@media screen and (max-width: 1200px) {
  .blog-hero {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  .blog-hero-content,
  .blog-hero-posts {
    height: auto;
    width: 100%;
    flex: 0 0 auto;
    margin: 0 auto;
  }
  .blog-hero-content h1 {
    margin-bottom: 0rem !important;
  }
  .blog-hero-content {
    padding: 3rem 1rem;
  }
  .unial-post .unial-post-body h1 {
    font-size: 1.1rem;
    line-height: 1;
  }
  .unial-post .unial-post-img img {
    height: 29vh;
  }
  .mail-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5rem;
  }
  .let-talk-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
  }
  .send-mail {
    height: 75vh;
  }
  .let-talk {
    height: 45vh;
  }
}
@media screen and (max-width: 767px) {
  .blog-hero-content h1 {
    font-size: 3rem;
  }
  .blog-hero-content p {
    line-height: normal;
  }
}
