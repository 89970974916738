.services_box_items {
    width: 8rem;
}
.services_box_items .service-text {
    font-size: 0.8rem;
    font-weight: 600;
    display: block;
}
.service-text{
    margin: 0 !important;
}
.services_box_items .service-text:hover {
    color: #7f0000;
    cursor: pointer;
}
.link-dropdown{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.navbardropDownPills {
    background-color: white;
    color: white;
    justify-content: start;
    border-radius: 5px;
    padding: 3px;
}

ul li .nav-link {
    text-align: start;
    cursor: pointer;
    background-color: transparent !important;
}
ul li .dark-link {
    color: #fff !important;
    font-weight: 500;
}
ul li .white-link {
    color: #000 !important;
    font-weight: 900;
}
ul li .nav-link:hover {
    color: #7f0000 !important;
}

.dropDownTechName {
    margin: 0px 10px;
    font-size: 0.7em;
    font-weight: 700;
}

.dropDownTechName:hover {
    color: #7f0000;
    cursor: pointer;
}

.dropdown-Headings {
    font-weight: bold;
}

.dropDownTechBox {
    position: relative;
}

.dropDownTechSection {
    position: relative;
}

.closeBtn {
    position: absolute;
    top: 0;
    right: 0;
}

.backBtn {
    position: absolute;
    top: 0;
    left: 0;
}
.swipeableArrow:hover{
    cursor: pointer;
}
.dropDownTechBoxRow2{
    display: none !important;
    /* background-color: red; */
}
.swipeablediv2{
    display: none !important;
    /* background-color: yellow; */
}
.swipeablediv{
    flex:1 0 235%;
}

.swipeablediv2 {
    transition: transform 0.3s ease-out;
  }
  
  .swipeablediv.move-left {
    transform: translateX(-100%);
  }
  
  .swipeablediv2.move-left {
    transform: translateX(-200%);
  }



  

@media (max-width:991px) {
    .dropDownTechSection {
        position: absolute;
        background-color: white;
        top: 0px;
        height: 300px;
    }

    .openDrawer {
        display: block;
    }

    .closeDrawer {
        display: none;
    }


    .dropDownServicesImg {
        width: 50px;
    }
    .dropDownTechBoxRow{
        display: none !important;
    }
    .dropDownTechBoxRow2{
        display: block !important;
    }
    .swipeablediv2{
        display: block !important;
    }
}